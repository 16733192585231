export const services = [
	{
		id: 1,
		title: "NFT website design and development",
		description:
			"We are the standard-bearer in the industry when it comes to NFT website designing and development. Intuitive UI/UX and custom solutions built from scratch with react js, and next js make our site a work of art and seamless to navigate through. <br /> <br /> We use CSS animations,parallax effects etc to make visiting the site a premium experience for your audience. ",
	},
	{
		id: 2,
		title: "Smart Contract Development ",
		description:
			"Smart contracts are a new way to execute logic and transact value in the decentralized web3 world. <br /> <br />  We build advanced smart contracts following erc721,erc721A, and erc1155 standards and add custom features to rethink what’s possible in a decentralized trustless system. Our smart contracts are optimised for alow gas fee, scalability and adhere to the openzeppelin standard.",
	},
	{
		id: 3,
		title: "Minting Dapp Development",
		description:
			"The minting Dapp integrates with the front end and interacts with the smart contract making NFT minting possible. We build an advanced minting engine with etherjs and web3js that can handle thousands of minting requests at a time. Custom features requests like max mint, fetching data from the deployed contract, and custom notification are implemented without any issue. Our Minting dapps also have multi-wallet support including metamask, wallet connect,coinbase wallet etc.",
	},
	{
		id: 4,
		title: "NFT artworks",
		description:
			"Our creative team of designers and artists make stunning artworks that set your project apart from hundred other generic ones. We generate 5k/10k artworks in bulk to be uploaded in decentralised storage. Our NFT artworks are generated according to the rarity chart making some artworks more valuable than others.  A great website needs unique graphic elements and we ensure that is the case.",
	},
	{
		id: 5,
		title: "Metadata Generation",
		description:
			"Metadata is the information about an NFT including its traits and what makes it unique. We generate bulk metadata for 5k/10k collections according to the rarity chart. Inserting super rares, one of one’s into the collection, fixing metadata are included in our service.",
	},
	{
		id: 6,
		title: "Staking Website",
		description:
			"Staking is used by NFT projects to provide their holders with sustainable recurring  revenue. We develop staking contacts and staking websites that let the holders lock up their NFTs for a period of time and earn revenue based on the time and rarity of the NFT. We also build a native coin for the NFT project to disburse the rewards and create its own ecosystem.",
	},
	{
		id: 7,
		title: "Evolution and Breeding",
		description:
			"It’s very important for an NFT project to be unique and stand out from others to succeed. Evolution and breeding are two of the most effective ways to do so. Although it requires complex engineering, it’s well worth the investment for any NFT project. Our team of blockchain engineers implement innovative evolution and breeding mechanisms that will amaze your community and bring your project a step closer to success.",
	},
	{
		id: 8,
		title: "NFT Marketplace Development",
		description:
			"We develop end to end NFT marketplace solutions following  ERC1155 standards, allowing batch mint and lazy minting. Using state of the art technology, we make sure your platform is ultra-fast, easier to navigate and gives users a superb user experience. We use military-grade security in marketplace development so that your users' assets are safe, and the platform is hack proof.",
	},
];