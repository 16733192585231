import React from "react";
import "../styles/pages/_portfolio.scss";
import PortfolioSlider from "../components/sliders/PortfolioSlider";

const Portfolio = () => {
	return (
		<div
			id="portfolio"
			className="bg-slate-900 py-12 px-4 pt-[150px] overflow-hidden"
		>
			<h1
				className="mb-20 font-extrabold text-center text-white
            "
			>
				OUR PORTFOLIO
			</h1>
			<PortfolioSlider />
		</div>
	);
};

export default Portfolio;
