import React from "react";

const Map = () => {
	return (
		<div className="mapouter">
			<div className="gmap_canvas">
				<iframe
					width="100%"
					height="500"
					id="gmap_canvas"
					src="https://maps.google.com/maps?q=birming&t=&z=13&ie=UTF8&iwloc=&output=embed"
					frameborder="0"
					scrolling="no"
					marginheight="0"
					marginwidth="0"
				></iframe>
				<a href="https://fmovies-online.net"></a>
				<br />
				<a href="https://www.embedgooglemap.net">embed google map responsive</a>
			</div>
		</div>
	);
};

export default Map;
