import React, { useState } from "react";
import Map from "../components/map/Map";
import "../styles/pages/_contact.scss";

const Contact = () => {
	const [formdata, setFormdata] = useState({
		enquiry: "",
		nature_of_enquiry: "",
		first_name: "",
		last_name: "",
		phone_number: "",
		email: "",
		company_name: "",
		website: "",
		job_title: "",
		country: "",
		budget: "",
		find: "",
		completion_date: "",
		type_of_organization: "",
		message: "",
	});

	const handleChange = (e) => {
		setFormdata({ ...formdata, [e.target.name]: e.target.value });
	};

	return (
		<main id="contact" style={{ overflow: "hidden" }} className="bg-slate-900">
			{/* contact page top */}
			<section id="contact-top">
				<div className="max-w-[1044px] mx-auto">
					<div className="container">
						<div className="max-w-[553px] mb-[50px]">
							<h1 className="text-left mb-[40px] text-4xl font-extrabold text-white">
								Get in touch with <br /> NFT Agency
							</h1>
							<p className="text-left text-white">
								Looking for blockchain development or consulting services on
								your blockchain project? Complete the form below to contact{" "}
								<b>NFT Agency.</b>
							</p>
						</div>
						<div className="max-w-[326px]">
							<img src="/assets/man-coin.png" alt="logo" />
						</div>
					</div>
				</div>
			</section>

			{/* message box */}
			<section id="message-box" className="bg-black font-mono">
				<form className="box w-[97%]">
					<h1 className="text-center mb-[68px]">Leave us a message</h1>
					<div className="container grid md:grid-cols-2 xs:grid-cols-1 gap-[30px]">
						<label className="block">
							<span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-semibold text-black mb-3">
								Does your enquiry relate to blockchain?
							</span>
							<input type="radio" name="enquiry" /> 
							<label className="mr-4 text-sm font-bold">YES</label>
							<input type="radio" name="enquiry" /> 
							<label className="mr-4 text-sm font-bold">NO</label>
						</label>
						<label className="block">
							<span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-semibold text-black mb-3">
								Nature of Enquiry
							</span>
							<select className="mt-1 px-3 py-2 bg-white border placeholder-slate-400 ring-1 focus:ring-2 focus:ring-blue-500 ring-gray-900 block w-full sm:text-sm">
								<option>Select an Option</option>
								<option value="saab">Saab</option>
								<option value="opel">Opel</option>
								<option value="audi">Audi</option>
							</select>
						</label>
						<label className="block">
							<span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-semibold text-black mb-3">
								First Name
							</span>
							<input
								type="text"
								name="name"
								className="mt-1 px-3 py-2 bg-white border placeholder-slate-400 ring-1 focus:ring-2 focus:ring-blue-500 ring-gray-900 block w-full sm:text-sm"
							/>
						</label>
						<label className="block">
							<span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-semibold text-black mb-3">
								Last Name
							</span>
							<input
								type="text"
								name="name"
								className="mt-1 px-3 py-2 bg-white border placeholder-slate-400 ring-1 focus:ring-2 focus:ring-blue-500 ring-gray-900 block w-full sm:text-sm"
							/>
						</label>
						<label className="block">
							<span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-semibold text-black mb-3">
								Phone Number
							</span>
							<input
								type="text"
								name="phone"
								className="mt-1 px-3 py-2 bg-white border placeholder-slate-400 ring-1 focus:ring-2 focus:ring-blue-500 ring-gray-900 block w-full sm:text-sm"
							/>
						</label>
						<label className="block">
							<span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-semibold text-black mb-3">
								Email Address
							</span>
							<input
								type="email"
								name="email"
								className="mt-1 px-3 py-2 bg-white border placeholder-slate-400 ring-1 focus:ring-2 focus:ring-blue-500 ring-gray-900 block w-full sm:text-sm"
							/>
						</label>
						<label className="block">
							<span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-semibold text-black mb-3">
								Company Name
							</span>
							<input
								type="text"
								name="company"
								className="mt-1 px-3 py-2 bg-white border placeholder-slate-400 ring-1 focus:ring-2 focus:ring-blue-500 ring-gray-900 block w-full sm:text-sm"
							/>
						</label>
						<label className="block">
							<span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-semibold text-black mb-3">
								Website URL
							</span>
							<input
								type="text"
								name="website"
								className="mt-1 px-3 py-2 bg-white border placeholder-slate-400 ring-1 focus:ring-2 focus:ring-blue-500 ring-gray-900 block w-full sm:text-sm"
							/>
						</label>
						<label className="block">
							<span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-semibold text-black mb-3">
								Job Title
							</span>
							<input
								type="text"
								name="title"
								className="mt-1 px-3 py-2 bg-white border placeholder-slate-400 ring-1 focus:ring-2 focus:ring-blue-500 ring-gray-900 block w-full sm:text-sm"
							/>
						</label>
						<label className="block">
							<span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-semibold text-black mb-3">
								Country
							</span>
							<select className="mt-1 px-3 py-2 bg-white border placeholder-slate-400 ring-1 focus:ring-2 focus:ring-blue-500 ring-gray-900 block w-full sm:text-sm">
								<option>Select an Option</option>
								<option value="saab">Saab</option>
								<option value="opel">Opel</option>
								<option value="audi">Audi</option>
							</select>
						</label>
						<label className="block">
							<span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-semibold text-black mb-3">
								Estimated Budget
							</span>
							<select className="mt-1 px-3 py-2 bg-white border placeholder-slate-400 ring-1 focus:ring-2 focus:ring-blue-500 ring-gray-900 block w-full sm:text-sm">
								<option>Select an Option</option>
								<option value="saab">Saab</option>
								<option value="opel">Opel</option>
								<option value="audi">Audi</option>
							</select>
						</label>
						<label className="block">
							<span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-semibold text-black mb-3">
								How did you find out about us?
							</span>
							<select className="mt-1 px-3 py-2 bg-white border placeholder-slate-400 ring-1 focus:ring-2 focus:ring-blue-500 ring-gray-900 block w-full sm:text-sm">
								<option>Select an Option</option>
								<option value="saab">Saab</option>
								<option value="opel">Opel</option>
								<option value="audi">Audi</option>
							</select>
						</label>
						<label className="block">
							<span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-semibold text-black mb-3">
								Desired Completion Date
							</span>
							{/* <select className="mt-1 px-3 py-2 bg-white border placeholder-slate-400 ring-1 focus:ring-2 focus:ring-blue-500 ring-gray-900 block w-full sm:text-sm">
								<option>Select an Option</option>
								<option value="saab">Saab</option>
								<option value="opel">Opel</option>
								<option value="audi">Audi</option>
							</select> */}
							<input
								type="date"
								className="mt-1 px-3 py-2 bg-white border placeholder-slate-400 ring-1 focus:ring-2 focus:ring-blue-500 ring-gray-900 block w-full sm:text-sm"
								name="date"
								id="date"
							/>
						</label>
						<label className="block">
							<span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-semibold text-black mb-3">
								Type of Organisation
							</span>
							<select className="mt-1 px-3 py-2 bg-white border placeholder-slate-400 ring-1 focus:ring-2 focus:ring-blue-500 ring-gray-900 block w-full sm:text-sm">
								<option>Select an Option</option>
								<option value="saab">Saab</option>
								<option value="opel">Opel</option>
								<option value="audi">Audi</option>
							</select>
						</label>

						<label className="">
							<span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-semibold text-black mb-3 ml-[10px]">
								Custom Message
							</span>
							<textarea
								className="mt-1 px-3 py-2 bg-white border placeholder-slate-400 ring-1 focus:ring-2 focus:ring-blue-500 ring-gray-900 block w-full sm:text-sm"
								placeholder="Enter your message here"
								rows="5"
							></textarea>
						</label>
					</div>
					<div className="flex items-center justify-end gap-8">
						<button className="flex items-center gap-3 mt-6 ring-1 ring-black px-8 py-2 group">
							Reset
						</button>
						<button className="flex items-center gap-3 mt-6 ring-1 ring-black px-8 py-2 group bg-green-400">
							Submit
						</button>
					</div>
				</form>
			</section>

			{/* map */}
			{/* <section id="map">
				<div className="container">
					<div className="img-box">
						<img src="/assets/location.png" alt="logo" />
					</div>
					<div className="text-box">
						<h2>Labrys HQ</h2>
						<p>58/108 wicham street, fortitude valley, QLD, 4006</p>
					</div>
				</div>
			</section> */}
			<Map />
		</main>
	);
};

export default Contact;
