import React from "react";

const PortfolioCard = ({ index }) => {
	return (
		<div className="transition-all duration-300 hover:shadow-md hover:-translate-y-2 cursor-pointer">
			<img src={`/assets/portfolio/project-${index}.png`} alt="" srcset="" />
		</div>
	);
};

export default PortfolioCard;
