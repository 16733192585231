import React, { useEffect } from "react";

import { Switch, Route, useHistory } from "react-router-dom";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/effect-coverflow/effect-coverflow.scss";

// pages and components
import Footer from "./components/Footer";
import Header from "./components/Header";
import AboutUs from "./pages/AboutUs";
import Careers from "./pages/Careers";
import Contact from "./pages/Contact";
import LandingPage from "./pages/LandingPage";
import Services from "./pages/Services";
import Portfolio from "./pages/Portfolio";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import Refund from "./pages/Refund";

const App = () => {
	const history = useHistory();

	useEffect(() => {
		history.listen(() => {
			window.scrollTo(0, 0);
		});
	}, []);

	return (
		<>
			<header>
				<Header />
			</header>

			<Switch>
				<Route exact path="/">
					<LandingPage />
				</Route>
				<Route exact path="/about">
					<AboutUs />
				</Route>
				<Route exact path="/portfolio">
					<Portfolio />
				</Route>
				<Route exact path="/services">
					<Services />
				</Route>
				<Route exact path="/careers">
					<Careers />
				</Route>
				<Route exact path="/contact">
					<Contact />
				</Route>
				<Route exact path="/terms-and-conditions">
					<Terms />
				</Route>
				<Route exact path="/privacy-policy">
					<Privacy />
				</Route>
				<Route exact path="/refund-policy">
					<Refund />
				</Route>
			</Switch>
			<footer>
				<Footer />
			</footer>
		</>
	);
};

export default App;
