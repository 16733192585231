import React from "react";

const Form2 = () => {
	return (
		<div className="grid md:grid-cols-2 gap-7 px-8  max-w-4xl mx-auto font-mono">
			<label className="block">
				<span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-semibold text-black mb-3">
					Company Name
				</span>
				<input
					type="text"
					name="company"
					className="mt-1 px-3 py-2 bg-white border placeholder-slate-400 ring-1 focus:ring-2 focus:ring-blue-500 ring-gray-900 block w-full sm:text-sm"
				/>
			</label>
			<label className="block">
				<span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-semibold text-black mb-3">
					Company Name
				</span>
				<input
					type="text"
					name="company"
					className="mt-1 px-3 py-2 bg-white border placeholder-slate-400 ring-1 focus:ring-2 focus:ring-blue-500 ring-gray-900 block w-full sm:text-sm"
				/>
			</label>
			<label className="block">
				<span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-semibold text-black mb-3">
					Company Name
				</span>
				<input
					type="text"
					name="company"
					className="mt-1 px-3 py-2 bg-white border placeholder-slate-400 ring-1 focus:ring-2 focus:ring-blue-500 ring-gray-900 block w-full sm:text-sm"
				/>
			</label>
			<label className="block">
				<span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-semibold text-black mb-3">
					Company Name
				</span>
				<input
					type="text"
					name="company"
					className="mt-1 px-3 py-2 bg-white border placeholder-slate-400 ring-1 focus:ring-2 focus:ring-blue-500 ring-gray-900 block w-full sm:text-sm"
				/>
			</label>
		</div>
	);
};

export default Form2;
