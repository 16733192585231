import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./sidebar/Sidebar";

const Header = () => {
    const [navActive, setNavActive] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY >= 80) {
                setNavActive(true);
            } else {
                setNavActive(false);
            }
        });
    }, [navActive]);

    return (
        <>
            {/* navbar */}
            <nav id="navbar" className={`${navActive ? "active" : ""}`}>
                <div className="container">
                    <div className="logo-wrapper">
                        <Link to="/">
                            {/* <img className="logo" src="/assets/logo.png" alt="" srcset="" /> */}
                            <h1 className="text-2xl text-white font-extrabold">
                                BLOOMWEB3
                            </h1>
                        </Link>
                    </div>
                    <div className="nav-items">
                        <Link
                            spy={true}
                            smooth={true}
                            offset={0}
                            duration={500}
                            to="about"
                            className="nav-item"
                            activeClass="active"
                        >
                            About Us
                        </Link>
                        <Link
                            spy={true}
                            smooth={true}
                            offset={0}
                            duration={500}
                            to="services"
                            className="nav-item"
                            activeClass="active"
                        >
                            Services
                        </Link>
                        <Link
                            spy={true}
                            smooth={true}
                            offset={0}
                            duration={500}
                            to="portfolio"
                            className="nav-item"
                            activeClass="active"
                        >
                            Portfolio
                        </Link>
                        <Link
                            to="careers"
                            spy={true}
                            smooth={true}
                            offset={0}
                            duration={500}
                            className="nav-item"
                            activeClass="active"
                        >
                            Careers
                        </Link>
                        <Link
                            to="contact"
                            spy={true}
                            smooth={true}
                            offset={0}
                            duration={500}
                            className="nav-item"
                            activeClass="active"
                        >
                            Contact
                        </Link>
                    </div>
                    {/* <div className="nav-btn" onClick={_toggleSidebar}>
						<svg
							width="27"
							height="20"
							viewBox="0 0 27 20"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<rect y="0.181641" width="27" height="4.90909" fill="white" />
							<rect y="7.54492" width="27" height="4.90909" fill="white" />
							<rect y="14.9092" width="27" height="4.90909" fill="white" />
						</svg>
					</div> */}
                    <Sidebar />
                </div>
                {/* <div className={`sidebar ${sidebarActive ? "active" : ""}`}>
					<button className="close-btn" onClick={_toggleSidebar}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							stroke="white"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth={2}
								d="M6 18L18 6M6 6l12 12"
							/>
						</svg>
					</button>
					<div className="sidebar-items">
						<Link
							to="about"
							spy={true}
							smooth={true}
							offset={0}
							duration={500}
							className="nav-item"
							activeClass="active"
						>
							<p>About Us</p>
						</Link>
						<Link
							spy={true}
							smooth={true}
							offset={0}
							duration={500}
							to="services"
							className="nav-item"
							activeClass="active"
						>
							<p>Services</p>
						</Link>
						<Link
							to="portfolio"
							spy={true}
							smooth={true}
							offset={0}
							duration={500}
							className="nav-item"
							activeClass="active"
						>
							<p>Portfolio</p>
						</Link>
						<Link
							to="careers"
							spy={true}
							smooth={true}
							offset={0}
							duration={500}
							className="nav-item"
							activeClass="active"
						>
							<p>Careers</p>
						</Link>
						<Link
							to="contact"
							spy={true}
							smooth={true}
							offset={0}
							duration={500}
							className="nav-item"
							activeClass="active"
						>
							<p>Contact</p>
						</Link>
					</div>
				</div> */}
            </nav>
        </>
    );
};

export default Header;
