import React, { useEffect } from "react";

// Counter
import CountUp from "react-countup";

// Marquee
import Marquee from "react-fast-marquee";
import { useHistory } from "react-router-dom";
import ReviewSlider from "../components/sliders/ReviewSlider";
import "../styles/pages/_landing.scss";

const data = [
	"solana",
	"bitcoin",
	"optimism",
	"binance",
	"cardano",
	"polygon",
	"solana",
	"bitcoin",
	"optimism",
	"binance",
	"cardano",
	"polygon",
];

const LandingPage = () => {
	const history = useHistory();
	useEffect(() => {
		// var c = document.getElementById("canvas");
		// var $ = c.getContext("2d");
		// var col = function (x, y, r, g, b) {
		// 	$.fillStyle = "rgb(" + r + "," + g + "," + b + ")";
		// 	$.fillRect(x, y, 1, 1);
		// };
		// var R = function (x, y, t) {
		// 	return Math.floor(192 + 64 * Math.cos((x * x - y * y) / 300 + t));
		// };
		// var G = function (x, y, t) {
		// 	return Math.floor(
		// 		192 +
		// 			64 *
		// 				Math.sin((x * x * Math.cos(t / 4) + y * y * Math.sin(t / 3)) / 300)
		// 	);
		// };
		// var B = function (x, y, t) {
		// 	return Math.floor(
		// 		192 +
		// 			64 *
		// 				Math.sin(
		// 					5 * Math.sin(t / 9) +
		// 						((x - 100) * (x - 100) + (y - 100) * (y - 100)) / 1100
		// 				)
		// 	);
		// };
		// var t = 0;
		// var run = function () {
		// 	for (let x = 0; x <= 35; x++) {
		// 		for (let y = 0; y <= 35; y++) {
		// 			col(x, y, R(x, y, t), G(x, y, t), B(x, y, t));
		// 		}
		// 	}
		// 	t = t + 0.03;
		// 	window.requestAnimationFrame(run);
		// };
		// run();
	}, []);

	return (
		<main style={{ overflow: "hidden" }}>
			<section id="hero" className="">
				{/* <canvas
					id="canvas"
					width="32"
					height="32"
					className="absolute top-0 left-0 bottom-0 right-0 w-screen -z-10"
				/> */}
				{/* <div className="video-wrapper">
					<video
						src="/assets/video/video.mp4"
						autoPlay
						loop
						muted
						id="bg-video"
					></video>
				</div> */}
				<div className="container">
					<h1 className="text-white uppercase leading-normal text-center max-w-4xl mx-auto text-3xl md:text-4xl lg:text-6xl">
						{/* <span className="text-5xl">NFT starts with us</span> <br />{" "}
						<span className="text-6xl">and ends successful</span>
						<br /> <span className="text-7xl">with our team</span> */}
						NFT STARTS WITH US AND ENDS SUCCESSFUL WITH OUR TEAM
					</h1>
				</div>
			</section>

			<section id="works" className="">
				<div className="max-w-[1223px] bg-[#ffffff47] mx-auto flex justify-between md:divide-x items-center content md:rounded-2xl md:shadow-lg">
					<div className="text-center flex-1">
						<h2>
							<CountUp end={44} />
						</h2>
						<p>Clients and Partners</p>
					</div>
					<div className="text-center flex-1">
						<h2>
							<CountUp end={16} />
						</h2>
						<p>Countries with Clients</p>
					</div>
					<div className="text-center flex-1">
						<h2>
							<CountUp end={70} />
						</h2>
						<p>Projects completed</p>
					</div>
				</div>

				<div className="container flex justify-between items-center">
					<div className="global-box">
						<h1 className="text-left text-4xl font-extrabold max-w-lg leading-normal text-white">
							Global Trailblazer in Blockchain and NFT development{" "}
						</h1>
						<p className="max-w-[553px] text-left leading-normal text-base md:text-xl text-white">
							We believe in the power of NFT to unlock the potential of
							communities and people to achieve great things together.
						</p>
						<button
							className="font-medium ring-1 ring-blue-400 hover:ring-2 hover:bg-blue-500 px-6 py-3 text-white transition duration-150 rounded-full hover:shadow-lg text-base md:text-xl"
							onClick={() => history.push("/contact")}
						>
							Talk to us
						</button>
					</div>
					<div>
						{/* <img src="/assets/global-img.png" alt="logo" /> */}
					</div>
				</div>
			</section>

			<section id="building" className="bg-white">
				<div className="container">
					<div className="flex justify-between flex-col lg:flex-row content gap-10">
						<div>
							<div className="bg-black flex items-center gap-6 md:gap-10 p-5 rounded mb-5">
								<div className="max-w-[102px]">
									<img
										className="ml-4 mb-3"
										src="/assets/services1.png"
										alt=""
									/>
									<h4 className="font-[700] text-center text-white">
										Consulting
									</h4>
								</div>
								<p className="text-white max-w-[600px]">
									Get access to your team of experts to help you conceptualize
									and decide on the design, user experience and tech stack and
									offer insights that set your project up for success.{" "}
								</p>
							</div>
							<div className="bg-black flex items-center gap-6 md:gap-10 p-5 rounded mb-5">
								<div className="max-w-[102px]">
									<img
										className="ml-5 mb-3"
										src="/assets/services2.png"
										alt=""
									/>
									<h4 className="font-[700] text-center text-white">
										Design and Prototyping
									</h4>
								</div>
								<p className="text-white max-w-[600px]">
									Engage Leviito’s artists, designers, and product managers to
									design your NFT website, create complex graphic elements and
									generate stunning artworks in bulk.
								</p>
							</div>
							<div className="bg-black flex items-center gap-6 md:gap-10 p-5 rounded mb-5">
								<div className="max-w-[102px]">
									<img
										className="ml-4 mb-3"
										src="/assets/services1.png"
										alt=""
									/>
									<h4 className="font-[700] text-center text-white">
										Developing
									</h4>
								</div>
								<p className="text-white">
									Our industry-leading blockchain engineers create highly
									scalable and optimised solutions that can serve millions of
									users. Services include smart contracts, minting app, wallet
									integration, Merkle tree whitelisting, web & mobile apps,
									MVPs, tokens, layer-2 scaling and more.
								</p>
							</div>
						</div>
						<div className="max-w-[541px] text-box">
							<p className="mb-[20px] font-bold text-white">Services</p>
							<h1 className="mb-4 md:mb-[20px] text-2xl md:text-4xl leading-normal text-white">
								Get your NFT project <br /> built by the best
							</h1>
							<p className="mb-[27px] text-base md:text-xl text-white">
								BloomWeb3 has some of the world's finest developers, designers,
								and creative artists helping you conceptualise and build
								phenomenal NFT projects
							</p>
							<button
								className="font-medium ring-1 ring-blue-400 hover:ring-2 hover:bg-blue-500 px-6 py-3 text-white transition duration-150 rounded-full hover:shadow-lg text-base md:text-xl"
								onClick={() => history.push("/contact")}
							>
								Start Building
							</button>
						</div>
					</div>
				</div>
			</section>

			<Marquee gradient={false}>
				<div className="flex items-center pt-4">
					{data.map((item) => (
						<div
							key={item}
							className="flex items-center gap-4 md:gap-6 py-1 md:py-2 px-6 md:px-11"
						>
							<img
								className="w-12 md:w-16"
								src={`/assets/coins/${item}.png`}
								alt={item}
							/>
							<p className="text-sm md:text-2xl text-white font-bold">{item}</p>
						</div>
					))}
				</div>
			</Marquee>
			<section id="metaverse" className="pb-20">
				<div className="container">
					<h1 className="text-5xl md:text-8xl text-white leading-loose">
						Enterprise <br /> Web3 <br /> NFT <br /> Metaverse
					</h1>
				</div>
			</section>

			<section
				id="client"
				className="py-[58px] flex items-center flex-col"
			>
				{/* <div className="max-w-5xl mb-[70px] px-4">
					<h1 className="font-extrabold font-5xl text-center mb-10 text-white">
						Our Trusted Clients
					</h1>
					<div className="grid grid-rows-2 md:grid-cols-2 gap-14">
						<div className="p-4 rounded-md bg-[#F6F7FC]">
							<div className="flex items-center justify-between gap-10 mb-6">
								<img src="/assets/client-1.png" alt="client-1" />
								<div className="h-28 w-28 rounded-full bg-gray-400 flex items-center justify-center text-3xl font-extrabold text-white">
									$20m+
								</div>
							</div>
							<div className="flex items-center justify-between gap-10">
								<a
									href="#"
									target="_blank"
									rel="noopener noreferrer"
									className="text-blue-500 text-sm whitespace-nowrap"
								>
									learn more..
								</a>
								<p className="font-bold text-lg md:text-2xl">
									Total Value Locked
								</p>
							</div>
						</div>
						<div className="p-4 rounded-md bg-[#F6F7FC]">
							<div className="flex items-center justify-between gap-10 mb-6">
								<img src="/assets/client-1.png" alt="client-1" />
								<div className="h-28 w-28 rounded-full bg-gray-400 flex items-center justify-center text-3xl font-extrabold text-white">
									$20m+
								</div>
							</div>
							<div className="flex items-center justify-between gap-10">
								<a
									href="#"
									target="_blank"
									rel="noopener noreferrer"
									className="text-blue-500 text-sm whitespace-nowrap"
								>
									learn more..
								</a>
								<p className="font-bold text-lg md:text-2xl">
									Total Value Locked
								</p>
							</div>
						</div>
						<div className="p-4 rounded-md bg-[#F6F7FC]">
							<div className="flex items-center justify-between gap-10 mb-6">
								<img src="/assets/client-1.png" alt="client-1" />
								<div className="h-28 w-28 rounded-full bg-gray-400 flex items-center justify-center text-3xl font-extrabold text-white">
									$20m+
								</div>
							</div>
							<div className="flex items-center justify-between gap-10">
								<a
									href="#"
									target="_blank"
									rel="noopener noreferrer"
									className="text-blue-500 text-sm whitespace-nowrap"
								>
									learn more..
								</a>
								<p className="font-bold text-lg md:text-2xl">
									Total Value Locked
								</p>
							</div>
						</div>
					</div>
				</div> */}

				{/* Client Reviews */}
				<div className="max-w-7xl mt-24">
					<h1 className="font-extrabold font-5xl text-center mb-32 text-white">
						What our clients say
					</h1>
					<div className="grid grid-rows-1 gap-14">
						<ReviewSlider />
					</div>
				</div>
			</section>

			{/* <section id="clients">
        <div className="container">
          <h1 className="text-center my-5">Our Trusted Clients</h1>
          <div>
            <div></div>
          </div>
        </div>
      </section> */}
		</main>
	);
};

export default LandingPage;
