import React from "react";

const ReviewCard = ({ active, name, des, index }) => {
	return (
		<div
			className={`p-4 rounded-md bg-[#F6F7FC] cursor-pointer transition-all duration-300 hover:shadow-2xl max-w-xs md:max-w-md mx-auto ${
				active ? "-mt-12" : ""
			}`}
		>
			<div className="flex items-center flex-col mb-6">
				<div className="px-4 py-8 rounded-3xl mb-4">
					<img
						src={`/assets/reviews/${
							index === 2 ? "2.jpg" : index === 5 ? "5.gif" : index + ".png"
						}`}
						className="w-[60%] mx-auto rounded-full"
						alt="client-1"
					/>
				</div>
				<h4 className="text-2xl font-extrabold text-black mb-4">{name}</h4>
				<p className="font-normal text-sm text-center">{des}</p>
			</div>
		</div>
	);
};

export default ReviewCard;
