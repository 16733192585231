import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation, EffectCoverflow } from "swiper";

// components
import PortfolioCard from "../cards/PortfolioCard";

SwiperCore.use([Navigation, Pagination, EffectCoverflow]);

const portfolioSlider = () => {
	return (
		<div className="max-w-4xl mx-auto">
			<Swiper
				centeredSlides={true}
				slidesPerView="auto"
				effect="coverflow"
				watchOverflow={false}
				// spaceBetween={50}
				loop={true}
				breakpoints={{
					0: {
						spaceBetween: 0,
					},
					768: {
						spaceBetween: 50,
					},
				}}
				initialSlide="1"
				coverflowEffect={{
					rotate: 0,
					stretch: 100,
					depth: 150,
					modifier: 1.5,
					slideShadows: false,
				}}
			>
				{[1, 2, 3, 4, 5].map((item, i) => (
					<SwiperSlide key={i}>
						{({ isActive }) => (
							<PortfolioCard active={isActive} index={item} {...item} />
						)}
					</SwiperSlide>
				))}
			</Swiper>

			<div className="new-pagination flex items-center justify-center my-4"></div>
		</div>
	);
};

export default portfolioSlider;
