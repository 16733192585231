import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
	Pagination,
	Navigation,
	EffectCoverflow,
	Autoplay,
} from "swiper";

// components
import ReviewCard from "../cards/ReviewCard";

SwiperCore.use([Autoplay, Navigation, Pagination, EffectCoverflow]);

const reviews = [
	{
		name: "Degen Heroes",
		role: "",
		des: "I decided to go with Bloom Web3 learning about them from another project.   Was not disappointed. Especially, their engineering team is very competent.",
	},
	{
		name: "Mems NFT",
		role: "",
		des: "We were looking for strategic partners who will handle all things tech. Bloom Web3 provided us with excellent service, supported us throughout the mint process and helped us make the project succeed.",
	},
	{
		name: "Escaping Kim",
		role: "",
		des: "We were concerned about the community needing high gas fee and complex revenue split functions we may need. Bloom Web3 created a highly optimised erc721A  contract for low gas fees and added custom revenue  split functions in line with our requirements. Very impressed with their technical know how.",
	},
	{
		name: "Bored Spirits",
		role: "",
		des: "We wanted the NFT project to stand out from others in terms of artwork. The artworks they produced are stunning to say the least. Really happy with their service. ",
	},
];

const ReviewSlider = () => {
	return (
		<div className="container">
			<Swiper
				centeredSlides={true}
				breakpoints={{
					0: { slidesPerView: 1, spaceBetween: 0 },
					640: {
						slidesPerView: 2,
						spaceBetween: 20,
					},
					1080: {
						slidesPerView: 3,
						spaceBetween: 40,
					},
				}}
				autoplay={{ delay: 2000, pauseOnMouseEnter: true }}
				initialSlide="1"
			>
				{reviews.map((item, i) => (
					<SwiperSlide className="flex justify-center" key={i}>
						{({ isActive }) => (
							<ReviewCard active={isActive} index={i + 1} {...item} />
						)}
					</SwiperSlide>
				))}
			</Swiper>

			<div className="new-pagination flex items-center justify-center my-4"></div>
		</div>
	);
};

export default ReviewSlider;
