import React from "react";
import Map from "../components/map/Map";
import "../styles/pages/_about.scss";

const AboutUs = () => {
	return (
        <main style={{ overflow: "hidden" }}>
            {/* about top */}
            <section id="about-top">
                <div className="container grid md:grid-cols-2 gap-14 items-center">
                    <div className="text-box">
                        <h1 className="mb-10 max-w-3xl text-white text-3xl md:text-4xl">
                            We are America’s leading NFT development agency
                        </h1>
                        <p className="max-w-3xl text-base md:text-lg text-white">
                            BloomWeb3 is the global leader and trailblazer in
                            blockchain product development. We have designed,
                            developed, and ects using state of the art
                            engineering and blockchain technology. With our NFT
                            development services, We empower the community in
                            the new world of web3. <br /> <br />
                            We have helped startups, enterprises, and non-profit
                            institutions with successful NFT project launches
                            and helped gain footing in the web3 world.
                        </p>
                        <button className="font-medium mt-6 ring-1 ring-blue-400 hover:ring-2 hover:bg-blue-500 px-6 py-3 text-white transition duration-150 rounded-full hover:shadow-lg text-base md:text-xl">
                            Start building Today
                        </button>
                    </div>
                    <img src="/assets/man-coin.png" alt="logo" />
                </div>
            </section>

            {/* innovation */}
            <section id="innovation" className="mt-24">
                <div className="container">
                    <h1 className="mb-14 text-center text-white text-3xl md:text-4xl">
                        100% based in America, Operates on a global level
                    </h1>
                    <p className="text-center text-base md:text-lg text-white">
                        BloomWeb3 is one of the UK's one of the oldest and most
                        trusted blockchain development and NFT creatives agency
                        with a laser focus on web3 technologies from day one of
                        the company's start. We are growing at a rate of 140%
                        year over year with hundreds of satisfied customers. We
                        are 100% owned and operated in London and provide all of
                        our services in-house from our London office. Our expert
                        engineers excel in smart contracts, wallet integration
                        and website development, while our creative teams of
                        artists and designers produce stunning designs and
                        artworks.{" "}
                    </p>
                </div>
            </section>

            {/* founder */}
            {/* <section id="founder">
				<div className="container">
					<h1 className="mb-[40px] text-center text-white">
						About our founder
					</h1>
					<div className="founder-box">
						<div className="founder-content ">
							<div className="img-box">
								<img src="/assets/founder.png" alt="logo" />
							</div>
							<h2 className="text-white">MR. Madalin</h2>
							<p className="text-white">
								It is a long established fact that a reader will be distracted
								by the readable content of a page when looking at its layout.
							</p>
						</div>
					</div>
				</div>
			</section> */}

            {/* discovery */}
            <section id="discovery">
                <div className="container grid md:grid-cols-2 gap-14 items-center">
                    <div className="text-box">
                        <p className="top-text text-white">
                            Constant Discovery
                        </p>
                        <h1 className="mb-[30px] text-white">
                            The Innovation Lab{" "}
                        </h1>
                        <div className="max-w-[541px] text-justify ">
                            <p className="text-base md:text-lg text-white">
                                We have set up an innovation lab here at Bloom
                                Labs to push the NFT development technology to
                                its limit. We are constantly experimenting with
                                new possible features and make sure we are
                                making use of the potential of blockchain
                                technology in our projects. Our product managers
                                efficiently keep track of your projects and
                                stress test every functionality so that your can
                                scale to a million users.
                            </p>
                        </div>
                    </div>
                    <div className="img-box">
                        <img src="/assets/discovery.png" alt="logo" />
                    </div>
                </div>
            </section>

            {/* crypto wiz */}
            <section id="crypto">
                <div className="container grid md:grid-cols-2 gap-14 items-center">
                    <div className="img-box">
                        <img src="/assets/crypto.png" alt="logo" />
                    </div>
                    <div className="text-box">
                        <p className="mb-[20px] text-white">Crypto Wiz</p>
                        <h1 className="mb-[30px] text-white">
                            Build with the team <br /> that gets it{" "}
                        </h1>
                        <div className="max-w-[541px] text-justify">
                            <p className="text-base md:text-lg text-white">
                                The developer and designers of BloomWeb3 live
                                and breathe crypto, NFT, DeFi.We work with the
                                most advanced technologies but communicate with
                                you in layman's terms and help nring your vision
                                into reality. Whether you need NFT website,
                                smart contract development, NFT ar generation,
                                minting dapp, we got you covered. No need to
                                waste time in translation.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            {/* map */}
            <Map />
        </main>
    );
};

export default AboutUs;
