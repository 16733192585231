import React, { useState } from "react";
import { services } from "../data/data";
import "../styles/pages/_services.scss";

const Services = () => {
	const [activeIndex, setActiveIndex] = useState(1);
	return (
        <main style={{ overflow: "hidden" }}>
            {/* services top */}
            <section id="services-top" className="">
                <div className="container grid md:grid-cols-2 items-center">
                    <div>
                        <h1 className="mb-10 max-w-3xl text-white text-3xl md:text-4xl">
                            America’s leading provider of NFT consulting and
                            development services
                        </h1>
                        <p className="max-w-3xl text-base md:text-lg text-white">
                            Partnering with a leading NFT development agency
                            helps you offer something unique to the community
                            and set your project up for success. <br /> <br />
                            As the engineers and designers of the news
                            decentralized world, we help build the required
                            infrastructure to reimagine what can be possible by
                            empowering NFT communities. We help you reform
                            existing processes, build on the latest available
                            tech stack and become an industry innovator .
                        </p>
                    </div>
                    <img src="/assets/service-girl.svg" alt="service-girl" />
                </div>
            </section>

            {/* services provide */}
            <section id="services-provide">
                <div className="container">
                    <h1 className="mb-14 text-center text-white text-3xl md:text-4xl">
                        Services we provide
                    </h1>
                    <div className="grid md:grid-cols-2 gap-14 items-center">
                        <div className="">
                            {services.map((item, index) => (
                                <>
                                    <div
                                        key={index}
                                        className={`${
                                            item.id === activeIndex
                                                ? "bg-slate-800 text-white"
                                                : "text-gray-500 hover:bg-slate-800"
                                        }`}
                                        onClick={() => setActiveIndex(item.id)}
                                    >
                                        <h2 className="font-bold text-xl md:text-xl lg:text-2xl max-w-md mb-4 cursor-pointer py-3 px-4">
                                            {item.title}
                                        </h2>
                                    </div>
                                    {item.id === activeIndex ? (
                                        <div
                                            className="text-base md:hidden text-white py-3 px-4"
                                            dangerouslySetInnerHTML={{
                                                __html: item.description,
                                            }}
                                        />
                                    ) : (
                                        ""
                                    )}
                                </>
                            ))}
                        </div>
                        {services
                            .filter((item) => item.id === activeIndex)
                            .map((item, index) => (
                                <div
                                    key={index}
                                    className="hidden md:inline-block"
                                >
                                    <div>
                                        <p
                                            className="text-2xl text-white"
                                            dangerouslySetInnerHTML={{
                                                __html: item.description,
                                            }}
                                        />
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </section>

            {/* process */}
            <section id="process">
                <div className="container grid gap-14 grid-cols-2">
                    <div className="max-w-md">
                        <h1 className="text-white text-3xl md:text-4xl mb-4">
                            What's the process?
                        </h1>
                        <p className="text-white text-base md:text-lg">
                            From consultation and ideation to planning,
                            designing and developing, and through to project
                            delivery, our lean approach means your project will
                            be shipped with the highest quality.
                        </p>
                    </div>
                    <div className="grid grid-rows-2 grid-cols-2 gap-6 font-mono">
                        <div className="flex gap-2 bg-gray-300  text-lg md:text-xl lg:text-2xl rounded-xl p-5">
                            <span className="">1</span>
                            <span className="">Product Definition</span>
                        </div>
                        <div className="flex gap-2 bg-gray-300  text-lg md:text-xl lg:text-2xl rounded-xl p-5">
                            <span className="">2</span>
                            <span className="">Product Design</span>
                        </div>
                        <div className="flex gap-2 bg-gray-300  text-lg md:text-xl lg:text-2xl rounded-xl p-5">
                            <span className="">3</span>
                            <span className="">Product Development</span>
                        </div>
                        <div className="flex gap-2 bg-gray-300  text-lg md:text-xl lg:text-2xl rounded-xl p-5">
                            <span className="">4</span>
                            <span className="">Product Capability</span>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default Services;
