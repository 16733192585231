import React from "react";
import "../styles/pages/_careers.scss";

const Careers = () => {
	return (
		<main style={{ overflow: "hidden" }} className="bg-slate-900 text-white">
			<section id="careers">
				<div className="container">
					<div className="text-box">
						<h2>Career Opportunities</h2>
						<p className="text-white">
							We are looking for talented individuals to join our team Here are
							all our current job openings
						</p>
					</div>
					<div className="content grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-[20px]">
						<div className="job-box">
							<h3>Product Marketing Manager</h3>
							<p className="text-white">Location: USA</p>
						</div>
						<div className="job-box">
							<h3>Junier UI/UX Designer</h3>
							<p className="text-white">Location: USA</p>
						</div>
						<div className="job-box">
							<h3>Intern Node Js Developer</h3>
							<p className="text-white">Location: USA</p>
						</div>
						<div className="job-box">
							<h3>Front-end ReactJs Developer</h3>
							<p className="text-white">Location: Dhaka</p>
						</div>
						<div className="job-box">
							<h3>Graphic Designer</h3>
							<p className="text-white">Location: USA</p>
						</div>
						<div className="job-box">
							<h3>Backend Developer</h3>
							<p className="text-white">Location: USA</p>
						</div>
						<div className="job-box">
							<h3>Junier UI/UX Designer</h3>
							<p className="text-white">Location: USA</p>
						</div>
						<div className="job-box">
							<h3>Intern Node Js Developer</h3>
							<p className="text-white">Location: USA</p>
						</div>
						<div className="job-box">
							<h3>Front-end ReactJs Developer</h3>
							<p className="text-white">Location: USA</p>
						</div>
						<div className="job-box">
							<h3>Graphic Designer</h3>
							<p className="text-white">Location: USA</p>
						</div>
					</div>
				</div>
			</section>
		</main>
	);
};

export default Careers;
