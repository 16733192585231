import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
	AiFillLinkedin,
	AiFillFacebook,
	AiFillTwitterSquare,
	AiFillInstagram,
} from "react-icons/ai";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";

import Form1 from "./forms/Form1";
import Form2 from "./forms/Form2";
import Form3 from "./forms/Form3";

const Footer = () => {
	const [step, setStep] = useState(0);
	const history = useHistory();

	const handleStep = (type = "l") => {
		if (type === "l") {
			setStep(step - 1);
		} else {
			setStep(step + 1);
		}
	};
	return (
		<>
			{/* footer */}
			<footer id="footer" className="bg-slate-900">
				<div
					className={`container max-w-5xl rounded-none py-8 ${
						step > 0 ? "max-w-4xl" : ""
					}`}
				>
					<h1 className="text-center text-base md:text-lg mb-8 font-mono px-4">
						Want to consult with our experts? <br /> Let us know which of the
						below we can help you with.
					</h1>
					{step === 0 ? (
						<div className="services flex items-center">
							<div className="service-box1 min-h-[138px] min-w-[138px] cursor-pointer">
								<div className="text-box">
									<p className="font-semibold">NFT website.</p>
								</div>
								<div className="img-box">
									<img src="/assets/nft-black.png" alt="logo" />
								</div>
							</div>
							<div className="service-box2  min-h-[138px] min-w-[138px] cursor-pointer">
								<div className="text-box">
									<p>Smart Contract</p>
								</div>
								<div className="img-box">
									<img src="/assets/smart.png" alt="logo" />
								</div>
							</div>
							<div className="service-box3  min-h-[138px] min-w-[138px] cursor-pointer">
								<div className="text-box">
									<p>
										Metadata and <br /> Artwork
									</p>
								</div>
								<div className="img-box">
									<img src="/assets/nft-black.png" alt="logo" />
								</div>
							</div>
							<div className="service-box4  min-h-[138px] min-w-[138px] cursor-pointer">
								<div className="text-box">
									<p>Minting Dapp</p>
								</div>
								<div className="img-box">
									<img src="/assets/smart.png" alt="logo" />
								</div>
							</div>
							<div className="service-box5  min-h-[138px] min-w-[138px] cursor-pointer">
								<div className="text-box">
									<p>
										Something <br /> else
									</p>
								</div>
								<div className="img-box">
									<img src="/assets/plus.png" alt="logo" />
								</div>
							</div>
						</div>
					) : step === 1 ? (
						<Form1 />
					) : step === 2 ? (
						<Form2 />
					) : step === 3 ? (
						<Form3 />
					) : (
						""
					)}
					{/* <div className="flex justify-center items-center gap-14">
						{step > 0 ? (
							<button
								className="flex items-center gap-3 mt-6 ring-1 ring-black px-4 py-2 group"
								onClick={() => handleStep("l")}
							>
								<BsArrowLeft className="transition duration-150 group-hover:-translate-x-2" />
								<span className="font-medium">Prev</span>
							</button>
						) : (
							""
						)}
						{step < 3 ? (
							<button
								className="flex items-center gap-3 mt-6 ring-1 ring-black px-4 py-2 group"
								onClick={() => handleStep("r")}
							>
								<span className="font-medium">Next</span>
								<BsArrowRight className="transition duration-150 group-hover:translate-x-2" />
							</button>
						) : (
							<button
								className="flex items-center gap-3 mt-6 ring-1 ring-black px-4 py-2 group bg-green-400"
								onClick={() => handleStep("r")}
							>
								<span className="font-medium">Submit</span>
							</button>
						)}
					</div> */}
				</div>
			</footer>
			<div className="bg-black py-20">
				<div className="container">
					<div className="grid grid-rows-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10">
						<ul>
							<h4 className="text-left mb-6 text-xl">COMPANY</h4>
							<li
								className="text-white mb-3 cursor-pointer transition-all duration-200 hover:translate-x-2 w-fit"
								onClick={() => history.push("/about")}
							>
								ABOUT US
							</li>
							<li
								className="text-white mb-3 cursor-pointer transition-all duration-200 hover:translate-x-2 w-fit"
								onClick={() => history.push("/portfolio")}
							>
								TESTIMONIALS
							</li>
							<li
								className="text-white mb-3 cursor-pointer transition-all duration-200 hover:translate-x-2 w-fit"
								onClick={() => history.push("/portfolio")}
							>
								PORTFOLIO
							</li>
						</ul>
						<ul>
							<h4 className="text-left mb-6 text-xl">SEVICES</h4>
							<li
								className="text-white mb-3 cursor-pointer transition-all duration-200 hover:translate-x-2 w-fit"
								onClick={() => history.push("/services")}
							>
								WEBSITE
							</li>
							<li
								className="text-white mb-3 cursor-pointer transition-all duration-200 hover:translate-x-2 w-fit"
								onClick={() => history.push("/services")}
							>
								NFTs
							</li>
							<li
								className="text-white mb-3 cursor-pointer transition-all duration-200 hover:translate-x-2 w-fit"
								onClick={() => history.push("/services")}
							>
								SMART CONTRACT
							</li>
						</ul>
						<ul>
							<h4 className="text-left mb-6 text-xl">OTHERS</h4>
							<li
								className="text-white mb-3 cursor-pointer transition-all duration-200 hover:translate-x-2 w-fit"
								onClick={() => history.push("/terms-and-conditions")}
							>
								TERMS & CONDITIONS
							</li>
							<li
								className="text-white mb-3 cursor-pointer transition-all duration-200 hover:translate-x-2 w-fit"
								onClick={() => history.push("/privacy-policy")}
							>
								PRIVACY POLICY
							</li>
							<li
								className="text-white mb-3 cursor-pointer transition-all duration-200 hover:translate-x-2 w-fit"
								onClick={() => history.push("/refund-policy")}
							>
								REFUND POLICY
							</li>
						</ul>
						<ul>
							<h4 className="text-left mb-6 text-xl">SOCIAL LINKS</h4>
							<div className="social-links flex items-center gap-6">
								<AiFillLinkedin className="text-white h-9 w-9 cursor-pointer" />
								<AiFillTwitterSquare className="text-white h-9 w-9 cursor-pointer" />
								<AiFillInstagram className="text-white h-9 w-9 cursor-pointer" />
								<AiFillFacebook className="text-white h-9 w-9 cursor-pointer" />
							</div>
						</ul>
					</div>
				</div>
			</div>
		</>
	);
};

export default Footer;
