import Drawer from "rc-drawer";
import "rc-drawer/assets/index.css";
import { NavLink } from "react-router-dom";

const Sidebar = () => {
	return (
		<Drawer
			className="custom-drawer"
			placement="right"
			width="250px"
			height={null}
		>
			<div className="nav-items pt-20 flex items-center justify-center flex-col">
				<NavLink
					to="about"
					spy={true}
					smooth={true}
					offset={0}
					duration={500}
					className="nav-item text-center text-2xl block py-4 w-full"
					activeClass="active bg-blue-400 text-white"
				>
					About Us
				</NavLink>
				<NavLink
					spy={true}
					smooth={true}
					offset={0}
					duration={500}
					to="services"
					className="nav-item text-center text-2xl block py-4 w-full"
					activeClass="active bg-blue-400 text-white"
				>
					Services
				</NavLink>
				<NavLink
					to="portfolio"
					spy={true}
					smooth={true}
					offset={0}
					duration={500}
					className="nav-item text-center text-2xl block py-4 w-full"
					activeClass="active bg-blue-400 text-white"
				>
					Portfolio
				</NavLink>
				<NavLink
					to="careers"
					spy={true}
					smooth={true}
					offset={0}
					duration={500}
					className="nav-item text-center text-2xl block py-4 w-full"
					activeClass="active bg-blue-400 text-white"
				>
					Careers
				</NavLink>
				<NavLink
					to="contact"
					spy={true}
					smooth={true}
					offset={0}
					duration={500}
					className="nav-item text-center text-2xl block py-4 w-full"
					activeClass="active bg-blue-400 text-white"
				>
					Contact
				</NavLink>
			</div>
		</Drawer>
	);
};

export default Sidebar;
